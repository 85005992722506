import React, { useState } from 'react'
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom'
import { ROUTE_PATH } from '../../constant/urlConstant'
import { ICON_LOGO, LOGO, USER } from '../../assets/images'
import { Menu } from 'primereact/menu'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from 'primereact/tooltip';
import { USER_ROLE } from '../../constant/actionTypes/role'
import { logout } from '../../redux/action/auth/login'
import MzDialog from '../../common/MzDialog'
import CardComponent from '../../components/card'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from '../../redux/action/translator'
import { FaCopy } from 'react-icons/fa'; 
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { toast } from 'react-toastify'

const LayoutScreen = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const userRole = useSelector((state) => state.loginReducer?.userRole)
  const user = useSelector((state) => state.userProfileReducer?.user ?? null)

  const { firstName = '', lastName = '', profilePicture = '' } = user || {}

  const [isReadDailogVisible, setIsReadDialogVisible] = useState(false)

  const cancelRead = () => {
    setIsReadDialogVisible(false)
  }
  const handleOnReadRecord = () => {
    setIsReadDialogVisible(true)
  }
  const itemRenderer = (item) => {
    const isActive =
      item.commandPath === '/'
        ? location.pathname === item.commandPath
        : location.pathname.startsWith(item.commandPath)
    return (
      <div
        className={`p-menuitem-content mb-3 hover:bg-primary ${
          isActive ? 'bg-primary' : ''
        }`}
        onClick={() => item.command && item.command()}
      >
        <Link
          className={`flex align-items-center p-menuitem-link ${
            isActive ? 'text-white' : 'hover:text-white'
          }`}
        >
          <span className={item.icon} />
          <span className="mx-2 hidden md:block">{item.label}</span>
        </Link>
      </div>
    )
  }
  const handleLogout = () => {
    dispatch(logout())
  }
  const handleCopyLink = () => {
    const urlId = user?.urlId
    const BASEURL = process.env.REACT_APP_HOME_URL ?? ''
    const userUrl = `${BASEURL}/user-digital-card/${urlId}`
    const urlToShare = userUrl
    navigator.clipboard
      .writeText(urlToShare)
      .then(() => {
        toast.success(t('Link'))
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  const viewUserProfile = () => {
    const urlId = user?.urlId;
    const BASEURL = process.env.REACT_APP_HOME_URL ?? '';
    const userUrl = `${BASEURL}/user-digital-card/${urlId}`;
    window.open(userUrl, '_blank');
  };
  
  const allMenuItems = [
    {
      label: t('Dashboard'),
      icon: 'pi pi-microsoft',
      command: () => navigate(ROUTE_PATH.BASE.HOME),
      commandPath: ROUTE_PATH.BASE.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER]
    },
    {
      label: t('Company'),
      icon: 'pi pi-building',
      command: () => navigate(ROUTE_PATH.COMPANY.HOME),
      commandPath: ROUTE_PATH.COMPANY.HOME,
      roles: [USER_ROLE.SUPER_ADMIN]
    },
    {
      label: t('Employee'),
      icon: 'pi pi-user',
      command: () => navigate(ROUTE_PATH.EMPOLYEE.HOME),
      commandPath: ROUTE_PATH.EMPOLYEE.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN]
    },
    {
      label: t('Department'),
      icon: 'pi  pi-briefcase',
      command: () => navigate(ROUTE_PATH.DEPARTMENT.HOME),
      commandPath: ROUTE_PATH.DEPARTMENT.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN]
    },
    {
      label: t('Contacts'),
      icon: 'pi pi-address-book',
      command: () => navigate(ROUTE_PATH.CONTACT.HOME),
      commandPath: ROUTE_PATH.CONTACT.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER]
    },
    {
      label: t('Enquiry'),
      icon: 'pi pi-users',
      command: () => navigate(ROUTE_PATH.ENQUIRY.HOME),
      commandPath: ROUTE_PATH.ENQUIRY.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER]
    },
    {
      label: t('Organization'),
      icon: 'pi pi-globe',
      command: () => navigate(ROUTE_PATH.ORGANIZATION.HOME),
      commandPath: ROUTE_PATH.ORGANIZATION.HOME,
      roles: [USER_ROLE.ADMIN, USER_ROLE.USER]
    },
    {
      label: t('Services'),
      icon: 'pi pi-info-circle',
      command: () => navigate(ROUTE_PATH.AMENITIES.HOME),
      commandPath: ROUTE_PATH.AMENITIES.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN]
    },
    {
      label: t('Products'),
      icon: 'pi pi-tags',
      command: () => navigate(ROUTE_PATH.PRODUCTS.HOME),
      commandPath: ROUTE_PATH.PRODUCTS.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN]
    },
    {
      label: t('Categories'),
      icon: 'pi pi-sitemap',
      command: () => navigate(ROUTE_PATH.CATEGORY.HOME),
      commandPath: ROUTE_PATH.CATEGORY.HOME,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN]
    },
    {
      label: t('Logout'),
      icon: 'pi pi-power-off',
      command: () => handleLogout(),
      commandPath: ROUTE_PATH.LOGOUT,
      roles: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN, USER_ROLE.USER, '']
    }
  ]

  const getFilteredItems = () => {
    const filteredItems = allMenuItems.filter((item) =>
      item.roles.includes(userRole)
    )

    const itemsWithTemplate = filteredItems.map((item) => ({
      ...item,
      template: itemRenderer,
      items: item.items
        ? item.items.map((subItem) => ({ ...subItem, template: itemRenderer }))
        : undefined
    }))

    return [
      {
        template: () => (
          <span className="inline-flex  w-full text-center align-items-center justify-content-center gap-1 md:px-2">
            <div className="flex align-items-center ">
              <img
                src={LOGO}
                alt="sales ladder"
                className="h-2rem  hidden md:block "
              />
              <img
                src={ICON_LOGO}
                alt="sales ladder"
                className="h-2rem  md:hidden "
              />
            </div>
          </span>
        )
      },
      { separator: true },
      ...itemsWithTemplate
    ]
  }

  const items = getFilteredItems()

  const openLinkInNewTab = (url) => {
    const validUrl =
      url?.startsWith('http://') || url?.startsWith('https://')
        ? url
        : `https://${url}`
    window.open(validUrl, '_blank', 'noopener,noreferrer')
  }
  let otherLinks = {}
  try {
    const otherLinksString = user?.tenant?.otherLinks || '{}'
    otherLinks = JSON.parse(otherLinksString)
  } catch (error) {
    console.error('Error parsing otherLinks:', error)
  }

  const headerTemplate = () => {
    return (
      <>
        {user && (
          <div className="flex justify-content-center align-items-center w-full">
            <div style={{ minWidth: '360px', maxWidth: '360px' }}>
              <div className="w-full  flex align-items-center  ">
                {user.tenant?.companyLogo ? (
                  <img
                    src={user.tenant.companyLogo}
                    className="h-2rem w-2rem border-circle"
                    alt="Logo"
                  />
                ) : null}
                <div className="text-sm ml-2">
                  <span>{user?.tenant?.companyName ?? ''}</span>
                </div>
              </div>

              <div className="flex ml-2 w-full mt-3 gap-3">
                <i
                  className="pi pi-facebook"
                  style={{
                    cursor: !otherLinks.facebook ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.facebook && openLinkInNewTab(otherLinks.facebook)
                  }
                />
                <i
                  className="pi pi-instagram"
                  style={{
                    cursor: !otherLinks.instagram ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.instagram &&
                    openLinkInNewTab(otherLinks.instagram)
                  }
                />
                <i
                  className="pi pi-twitter"
                  style={{
                    cursor: !otherLinks.twitter ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.twitter && openLinkInNewTab(otherLinks.twitter)
                  }
                />

                <i
                  className="pi pi-linkedin "
                  style={{
                    cursor: !otherLinks.linkedin ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.linkedin && openLinkInNewTab(otherLinks.linkedin)
                  }
                />
                <i
                  className="pi pi-globe"
                  style={{
                    cursor: !otherLinks.website ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.website && openLinkInNewTab(otherLinks.website)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
  const handleChangeLanguage = (e) => {
    const newLanguage = e.value
    dispatch(changeLanguage(newLanguage))
  }
  const languageOptions = [
    { label: t('en'), value: 'en' },
    { label: t('hi'), value: 'hi' },
    { label: t('mr'), value: 'mr' },
    // { label: t('jv'), value: 'jv' },
    // { label: t('su'), value: 'su' },
    // { label: t('mad'), value: 'mad' },
    // { label: t('bug'), value: 'bug' },
    { label: t('min'), value: 'min' }
  ]
  const currentLanguage = useSelector(
    (state) => state.translatorReducer.language
  )

  return (
    <>
      <MzDialog
        header={() => headerTemplate()}
        visible={isReadDailogVisible}
        onHide={cancelRead}
        children={<CardComponent selectedEmployee={user} />}
      />
      <div
        style={{ width: 'calc(100vw - 15px)', height: 'calc(100vh - 20px)' }}
      >
        <div className="flex justify-content-between  gap-2 h-full">
          <div>
            <Menu
              model={items}
              className="md:w-14rem w-full h-full overflow-auto"
            />
          </div>

          <div className="flex flex-column gap-2 w-full h-full surface-border px-1">
            <div className="flex z-5 p-2 border-round-md surface-border border-1 align-items-center justify-content-end gap-2 sticky top-0 bg-white">
            <div className='text-xs'>
            <Tooltip target=".copy-button"  content={t('tenant.link')} position="bottom" />
            <Button onClick={viewUserProfile}  severity='secondary'>{t('View Profile')}</Button>
            </div>
              <Button
                icon={<FaCopy style={{ fontSize: '1.5rem',color: 'gray' }} />} 
                text
                aria-label="share"
                onClick={() => handleCopyLink()}
                className='copy-button'
              />
              <Dropdown
                value={currentLanguage}
                options={languageOptions}
                onChange={handleChangeLanguage}
                placeholder={t('language')}
                autoFocus={false}
                className="rounded  bg-transparent "
              />
              <div className="text-sm">
                {firstName ?? ''} {lastName ?? ''}
              </div>
              <img
                src={profilePicture ?? USER}
                className="h-2rem w-2rem cursor-pointer border-circle"
                alt="Profile"
                onClick={() => handleOnReadRecord()}
              />
            </div>

            <div className="flex-grow overflow-y-auto">
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="h-screen" style={{ width: 'calc(100vw - 15px)' }}>
        <div className="flex justify-content-between p-2 gap-2">
          <div>
            <Menu model={items} className="w-full md:w-15rem h-full" />
          </div>
          <div className="flex flex-column gap-2 w-full h-full surface-border  px-1">
            <div className="flex z-5 p-2 border-round-md surface-border border-1 align-items-center justify-content-end gap-2 sticky top-0 bg-white">
              <div className="text-sm">
                {firstName} &nbsp; {lastName}
              </div>
              <img
                src={profilePicture ?? USER}
                className="h-2rem w-2rem cursor-pointer border-circle"
                alt="Profile"
                onClick={() => handleOnReadRecord()}
              />
            </div>
            <div
              className="flex-grow  overflow-scroll  "
              style={{ height: 'calc(100vh - 5.5rem)' }}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default LayoutScreen
