import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import QRCode from 'qrcode.react'
import 'react-phone-input-2/lib/style.css'
import MzInput from '../../common/MzForm/MzInput'
import { FORM_FIELDS_NAME } from './constants'
import { Dialog } from 'primereact/dialog'
import { ICON_LOGO, USER } from '../../assets/images'
import MzPhoneInput from '../../common/MzForm/MzPhoneInput'
import { createExchangeInfoRecord } from '../../redux/action/contact'
import { MzToast, TOAST_SEVERITY } from '../../common/MzToast'
import { Carousel } from 'primereact/carousel'
import { fetchAmenitiesList } from '../../redux/action/amenities'
import { fetchProductsList } from '../../redux/action/products'
import MzTextarea from '../../common/MzForm/MzTextArea'
import axios from 'axios'
import { baseUrl } from '../../services/PostAPI'
import { API_PATH } from '../../constant/urlConstant'
import MzDialog from '../../common/MzDialog'
import { InputOtp } from 'primereact/inputotp'
import { Card } from 'primereact/card'
import { Timeline } from 'primereact/timeline'
import { fetchDepartmentRecord } from '../../redux/action/department'
import './index.css'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const CardComponent = ({ selectedEmployee, serviceId, data }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [serviceVisible, setServiceVisible] = useState(false)
  const [productVisible, setProductVisible] = useState(false)
  const [share, setShare] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)
  const [popupVisible, setPopupVisible] = useState(false)
  const [enquiryVisible, setEnquiryVisible] = useState(false)
  const [myEnquiryVisible, setMyEnquiryVisible] = useState(false)
  const [myEnquiryTrackVisible, setMyEnquiryTrackVisible] = useState(false)
  const [selectedEnquiry, setSelectedEnquiry] = useState(null)
  const [selectService, setSelectService] = useState([])
  const [selectProduct, setSelectProduct] = useState([])
  const [toastVisible, setToastVisible] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [isLiked, setIsLiked] = useState(false)
  const [isLikedProduct, setIsLikedProduct] = useState(false)
  const [productToastVisible, setProductToastVisible] = useState(false)

  // eslint-disable-next-line
  const [enquiryCount, setEnquiryCount] = useState('')
  const dispatch = useDispatch()
  const [isQRCode, setIsQRCode] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [showOtp, setShowOtp] = useState(false)
  const [otp, setOtp] = useState('')
  const [otpData, setOtpData] = useState([])
  const [otpError, setOtpError] = useState('')
  const [otpSuccess, setOtpSuccess] = useState('')

  // eslint-disable-next-line no-unused-vars
  const [isOtpVerified, setIsOtpVerified] = useState(false)
  const [otpPop, setOtpPop] = useState(false)

  const employee = selectedEmployee
  const userId = employee?.id
  const urlId = employee?.urlId
  const tenantId = employee?.tenant?.id
  let otherLinks = {}

  const url = `${baseUrl}${API_PATH.INTEREST.ADD}`
  const productIntresturl = `${baseUrl}${API_PATH.PRODUCTS.ADD_INQUIRY}`
  const countUrl = `${baseUrl}${API_PATH.INTEREST.COUNT}`
  const varifyOtp = `${baseUrl}${API_PATH.OTP_VERIFY.VERIFY}`
  const resendOtp = `${baseUrl}${API_PATH.RESEND_OTP.RESEND}`

  const BASEURL = process.env.REACT_APP_HOME_URL ?? ''
  const userUrl = `${BASEURL}/user-digital-card/${urlId}`
  const serviceUrl = `${BASEURL}/user-digital-card/${urlId}?serviceId=${selectService?.id}`
  // const productUrl = `${BASEURL}/user-digital-card/${urlId}?roducteId=${selectProduct?.id}`;

  const previewData = data

  const enquiries = [
    {
      code: '001',
      name: 'John Doe',
      category: 'Consulting',
      date: '2024-07-30',
      stages: {
        enquiryDate: '2024-07-30',
        secondCall: '2024-08-02',
        proposal: '2024-08-05'
      }
    },
    {
      code: '002',
      name: 'Jane Smith',
      category: 'Development',
      date: '2024-07-29',
      stages: {
        enquiryDate: '2024-07-29',
        secondCall: '2024-08-01',
        proposal: '2024-08-03'
      }
    }
  ]

  useEffect(() => {
    if (productToastVisible) {
      toast.error(productToastVisible.message)
      setProductToastVisible(null)
    }
  }, [productToastVisible])

  const payload = useMemo(() => ({ tenantId }), [tenantId])
  useEffect(() => {
    if (tenantId) {
      dispatch(fetchAmenitiesList(payload))
      dispatch(fetchProductsList(payload))
    }
  }, [dispatch, payload, tenantId])

  const aminitList = useSelector(
    (state) => state.amenitiesReducer?.amenitiesList?.content ?? []
  )

  const productsList = useSelector(
    (state) => state.productsReducer?.productList.content ?? []
  )

  const user = useSelector((state) => state.userProfileReducer?.user ?? null)

  useEffect(() => {
    if (previewData?.department) {
      dispatch(fetchDepartmentRecord(previewData.department))
    }
  }, [dispatch, previewData?.department])

  const selectedDepartment = useSelector(
    (state) => state.departmentReducer?.selectedDepartment
  )

  const array = [...aminitList, ...productsList]
  const getEnquiryCount = useCallback(
    async (serviceId) => {
      try {
        const result = await axios.get(`${countUrl}/${serviceId}`)
        setEnquiryCount(result.data)
      } catch (error) {
        console.error('Error fetching enquiry count:', error)
      }
    },
    [countUrl]
  )

  const showServiceDialog = useCallback(
    (data) => {
      setSelectProduct(null)
      getEnquiryCount(data?.id)
      setIsLiked(false)
      setServiceVisible(true)
      setSelectService(data)
    },
    [getEnquiryCount]
  )

  useEffect(() => {
    if (!serviceId) {
      setServiceVisible(false)
      return
    }

    if (aminitList && Array.isArray(aminitList)) {
      const idNumber = Number(serviceId)
      const data = aminitList.find((item) => item?.id === idNumber)
      if (data) {
        showServiceDialog(data)
        setSelectService(data)
        setServiceVisible(true)
      } else {
        setServiceVisible(false)
      }
    }
  }, [serviceId, aminitList, showServiceDialog])

  const createTimelineEvents = (stages) => {
    return [
      { status: 'Enquiry Date', date: stages.enquiryDate },
      { status: 'Second Call', date: stages.secondCall },
      { status: 'Proposal', date: stages.proposal }
    ]
  }

  const showDetails = (enquiry) => {
    setSelectedEnquiry(enquiry)
    setMyEnquiryTrackVisible(true)
  }

  const closeDialog = () => {
    setMyEnquiryTrackVisible(false)
  }
  const closeOtpPop = () => {
    setOtpPop(false)
  }

  const isCreateContactSuccess = useSelector(
    (state) => state?.contactReducer?.isCreateContactSuccess ?? false
  )
  const isCreateContactError = useSelector(
    (state) => state?.contactReducer?.isCreateContactError ?? false
  )

  try {
    const otherLinksString = employee?.tenant?.otherLinks || '{}'
    otherLinks = JSON.parse(otherLinksString)
  } catch (error) {
    console.error('Error parsing otherLinks:', error)
  }

  const headerTemplate = () => {
    return (
      <>
        {employee && (
          <div className="flex justify-content-center align-items-center w-full">
            <div style={{ minWidth: '360px', maxWidth: '360px' }}>
              <div className="w-full  flex align-items-center  ">
                {employee.tenant?.companyLogo ? (
                  <img
                    src={employee.tenant.companyLogo}
                    className="h-2rem w-2rem border-circle"
                    alt="Logo"
                  />
                ) : null}
                <div className="text-sm ml-2">
                  <span>{employee?.tenant?.companyName ?? ''}</span>
                </div>
              </div>

              <div className="flex ml-2 w-full mt-3 gap-3">
                <i
                  className="pi pi-facebook"
                  style={{
                    cursor: !otherLinks.facebook ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.facebook && openLinkInNewTab(otherLinks.facebook)
                  }
                />
                <i
                  className="pi pi-instagram"
                  style={{
                    cursor: !otherLinks.instagram ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.instagram &&
                    openLinkInNewTab(otherLinks.instagram)
                  }
                />
                <i
                  className="pi pi-twitter"
                  style={{
                    cursor: !otherLinks.twitter ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.twitter && openLinkInNewTab(otherLinks.twitter)
                  }
                />

                <i
                  className="pi pi-linkedin "
                  style={{
                    cursor: !otherLinks.linkedin ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.linkedin && openLinkInNewTab(otherLinks.linkedin)
                  }
                />
                <i
                  className="pi pi-globe"
                  style={{
                    cursor: !otherLinks.website ? 'not-allowed' : 'pointer'
                  }}
                  onClick={() =>
                    otherLinks.website && openLinkInNewTab(otherLinks.website)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  try {
    const otherLinksString = employee?.tenant?.otherLinks || '{}'
    otherLinks = JSON.parse(otherLinksString)
  } catch (error) {
    console.error('Error parsing otherLinks:', error)
  }

  const getToastProps = () => {
    if (isCreateContactSuccess) {
      const toastTitle = 'Contact Exchange Successfully'
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }

    if (isCreateContactError) {
      let toastTitle = 'Error while Creating Contact'

      return {
        severity: TOAST_SEVERITY.ERROR,
        toastTitle,
        shouldShowToast: true
      }
    }
    if (toastVisible) {
      const toastTitle = toastMessage
      return {
        severity: TOAST_SEVERITY.SUCCESS,
        toastTitle,
        shouldShowToast: true
      }
    }
  }

  const showDialog = (data, isQRCode = false) => {
    setSelectedImage(data)
    setIsQRCode(isQRCode)
    setVisible(true)
  }

  const showProductsDialog = (data) => {
    setSelectService(null)
    getProductsEnquiryCount(data?.id)
    setIsLikedProduct(false)
    setProductVisible(true)
    setSelectProduct(data)
  }

  const hideServiceDialog = () => {
    setServiceVisible(false)
  }

  const hideProductsDialog = () => {
    setProductVisible(false)
  }
  const hideDialog = () => {
    setVisible(false)
    setServiceVisible(false)
    setSelectedImage(null)
  }

  const openPopup = () => {
    setOtpSuccess('')
    setOtpError('')
    reset()
    setPopupVisible(true)
  }

  const closePopup = () => {
    setPopupVisible(false)
    setIsOtpVerified(false)
    reset()
  }

  const openEnquiryPopup = () => {
    setOtpSuccess('')
    setOtpError('')
    setEnquiryVisible(true)
  }

  const closeEnquiryPopup = () => {
    setEnquiryVisible(false)
    setIsOtpVerified(false)
  }
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    clearErrors,
    reset
  } = useForm({
    defaultValues: null,
    mode: 'onChange'
  })

  const getFormErrorMessage = (name) =>
    errors[name] && <small className="p-error">{t(errors[name].message)}</small>

  const onOtpSubmit = async () => {
    try {
      const url = `${varifyOtp}?email=${otpData}&otp=${encodeURIComponent(otp)}`
      const response = await axios.post(url)
      if (response) {
        setOtpSuccess('OTP Verified Successfully')
        setOtpError('')
        setTimeout(() => {
          setOtpPop(false)
        }, 1000)
        setIsOtpVerified(true)
        setShowOtp(false)
        closePopup()
        closeEnquiryPopup()
      } else {
        setOtpError('Invalid OTP, please try again')
        setOtpSuccess('')
        setIsOtpVerified(false)
      }
    } catch (error) {
      setOtpError(error.response?.data?.message)
      setOtpSuccess('')
    }
  }

  const onOtpResend = async () => {
    try {
      const url = `${resendOtp}?email=${otpData}`
      const response = await axios.post(url)
      if (response) {
        setOtpSuccess('OTP Resent Successfully')
        setOtpError('')
        setIsOtpVerified(true)
      } else {
        setOtpError('Invalid OTP, please try again')
        setOtpSuccess('')
        setIsOtpVerified(false)
      }
    } catch (error) {
      setOtpError('An error occurred while resending OTP. Please try again.')
      setOtpSuccess('')
    }
  }

  const onSubmit = async (data) => {
    if (!isValid) return
    const { [FORM_FIELDS_NAME.CONFIRM_EMAIL.name]: _, ...formData } = data
    try {
      const modifiedData = {
        ...formData,
        user: { id: userId, tenant: { id: tenantId } }
      }
      setOtpData(modifiedData.email)
      dispatch(createExchangeInfoRecord(modifiedData))
      setOtp('')
      setOtpSuccess('')
      setShowOtp(false)
      setOtpPop(true)
    } catch (error) {
      setOtpPop(false)
      closePopup()
      console.error(error.response ? error.response.data : error.message)
    }
  }
  const email = watch(FORM_FIELDS_NAME.EMAIL.name)

  useEffect(() => {
    if (email) {
      clearErrors(FORM_FIELDS_NAME.CONFIRM_EMAIL.name)
    }
  }, [email, clearErrors])

  const onEnquirySubmit = async (data) => {
    try {
      const payload = {
        notes: data.notes,
        additionalInfo: data.additionalInfo,
        service: { id: selectService.id },
        contacts: {
          name: data.name,
          phone: data.phone,
          email: data.email,
          address: data.address,
          user: { id: userId }
        }
      }
      setOtpData(payload.contacts.email)

      const result = await axios.post(url, payload)
      if (result) {
        setOtpPop(true)
        setToastMessage('Enquiry Sent successful!')
        setToastVisible(true)
        setOtp('')
        setOtpSuccess('')
        setShowOtp(false)
      }
    } catch (error) {
      closeEnquiryPopup()
      setProductToastVisible(error.response.data)
      // toast.error(error.response ? error.response.data : error.message)
      //   setToastMessage(error.response ? error.response.data : error.message)
      //   setToastVisible(true)
      //   closeEnquiryPopup()
      //   setOtpPop(false)
      //   console.error(error.response ? error.response.data : error.message)
      //   setTimeout(() => {
      //     setToastVisible(false)
      //   }, 3000)
    }
  }

  const onProductEnquirySubmit = async (data) => {
    try {
      const payload = {
        notes: data.notes,
        additionalInfo: data.additionalInfo,
        product: { id: selectProduct.id },
        quantity: data.quantity,
        contacts: {
          name: data.name,
          phone: data.phone,
          email: data.email,
          address: data.address,
          user: { id: userId }
        }
      }
      setOtpData(payload.contacts.email)

      const result = await axios.post(productIntresturl, payload)
      if (result) {
        setOtpPop(true)
        setToastMessage('Enquiry Sent successful!')
        setToastVisible(true)
        setOtp('')
        setOtpSuccess('')
        setShowOtp(false)
      }
    } catch (error) {
      closeEnquiryPopup()
      setProductToastVisible(error.response.data)

      // setOtpPop(false)
      // setToastMessage(error.response ? error.response.data : error.message)
      // console.error(error.response ? error.response.data : error.message)
      // setTimeout(() => {
      //   setToastVisible(false)
      // }, 3000)
    }
  }

  const postInterested = async () => {
    try {
      await axios.post(`${baseUrl}/services-interest/${selectService.id}`)
    } catch (error) {
      console.error('Error posting interest:', error)
    }
  }

  const ProductpostInterested = async () => {
    try {
      await axios.post(`${baseUrl}/products-interest/${selectProduct.id}`)
    } catch (error) {
      console.error('Error posting interest:', error)
    }
  }

  const getProductsEnquiryCount = async (productsId) => {
    try {
      const result = await axios.get(`${countUrl}/${productsId}`)
      setEnquiryCount(result.data)
    } catch (error) {
      console.error('Error posting interest:', error)
    }
  }

  const handleMailClick = () => {
    window.location.href = `mailto:${employee?.email}`
  }

  const handleCallClick = () => {
    window.open(`tel:${employee?.tenant?.phoneNumber}`, '_blank')
  }

  const fetchUserCards = async () => {
    try {
      await axios.get(
        `${baseUrl}/public/user/${user.urlId}?eventType=CARD_DOWNLOAD`
      )
    } catch (error) {
      if (error.response) {
        if (error.response.status === 403) {
          console.warn(
            'Access forbidden: You do not have permission to view this resource.'
          )
        } else {
          console.error(`Error ${error.response.status}:`, error.response.data)
        }
      } else if (error.request) {
        console.error('No response received:', error.request)
      } else {
        console.error('Error in setting up request:', error.message)
      }
    } finally {
      console.log('Fetch user cards attempt finished.')
    }
  }

  const handleDownloadVCard = () => {
    fetchUserCards()
    // Construct vCard data
    const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:${employee?.username ?? 'Username not available'}
ORG:${employee?.tenant?.companyName ?? 'Company not available'}
TITLE:${employee?.department?.name ?? 'Department not available'}
TEL;TYPE=WORK,VOICE:${employee?.tenant?.phoneNumber ?? ''}
EMAIL:${employee?.email ?? ''}
URL:${employee?.tenant?.website ?? ''}
END:VCARD
`.trim() // Ensure no extra whitespace

    // Check if vCard data is not empty
    if (!vCardData || !vCardData.includes('BEGIN:VCARD')) {
      console.error('Invalid vCard data')
      return
    }

    // Create a Blob object with the vCard data
    const blob = new Blob([vCardData], { type: 'text/vcard' })

    // Create a temporary download link
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'contact.vcf'

    // Append the link to the document, trigger the download, and then remove the link
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // Release the object URL to free up resources
    URL.revokeObjectURL(link.href)
  }

  const handleShare = (platform) => {
    let shareUrl = ''
    const urlToShare = selectService && selectService.id ? serviceUrl : userUrl

    if (platform) {
      setShare(true)
      switch (platform) {
        case 'whatsapp':
          shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            urlToShare
          )}`
          break
        case 'facebook':
          shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            urlToShare
          )}`
          break
        case 'instagram':
          alert('Copy the link and share it on Instagram.')
          return
        case 'linkedin':
          shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            urlToShare
          )}`
          break
        case 'twitter':
          shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            urlToShare
          )}`
          break
        case 'copy':
          if (navigator.clipboard.writeText(urlToShare)) {
            toast.success('Link copied to clipboard')
          } else {
            toast.error('Link not copied to clipboard')
          }

        // eslint-disable-next-line no-fallthrough
        default:
          return
      }
    }

    setShare(true)
    window.open(shareUrl, '_blank')
  }

  const handleDownloadBrochure = () => {
    const link = document.createElement('a')
    link.href = selectService ? selectService.brochure : selectProduct.brochure
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const openLinkInNewTab = (url) => {
    const validUrl =
      url?.startsWith('http://') || url?.startsWith('https://')
        ? url
        : `https://${url}`
    window.open(validUrl, '_blank', 'noopener,noreferrer')
  }

  const itemTemplate = (item) => {
    return (
      <div
        className="flex flex-column justify-content-center align-items-center border-round w-full"
        style={{ height: '240px', width: '360px' }}
      >
        <div className="flex justify-content-evenly align-items-center border-circle text-xs">
          <div className="flex justify-cotent-center col-2">
            {item.icon ? (
              <img
                src={item.icon}
                className="h-2rem w-2rem border-circle"
                alt="Logo"
              />
            ) : null}
          </div>
          <div className="font-bold text-sm mr-2 text-900 col-10 flex justify-content-center">
            <span>{item.name ?? ''}</span>
          </div>
        </div>
        {item.video && (
          <video controls className="w-full mb-2" autoPlay loop muted>
            <source src={item?.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        {item.description && (
          <div className="text-900 text-wrap max-h-4rem text-sm flex">
            <>
              <div className="text-center">
                {`${item.description.substring(0, 50)}...`}
                <Button
                  label={t('Show More')}
                  onClick={() =>
                    item.category
                      ? showProductsDialog(item)
                      : showServiceDialog(item)
                  }
                  text
                  className="text-indigo-500 text-xs h-1rem py-2 px-2 mt-2 underline outline-none border-none "
                ></Button>
              </div>
            </>
          </div>
        )}
      </div>
    )
  }

  const toggleLike = () => {
    setIsLiked(!isLiked)
    postInterested()
  }

  // const myEnquiry = () => {
  //   setMyEnquiryVisible(true)
  // }
  const closeMyServicesPopup = () => {
    setMyEnquiryVisible(false)
  }

  const toggleLikeProduct = () => {
    setIsLikedProduct(!isLikedProduct)
    ProductpostInterested()
  }

  return (
    <>
      <MzToast {...getToastProps()} />
      <div className="flex justify-content-center w-full">
        <div style={{ maxWidth: '360px', minWidth: '350px' }}>
          <div className="card px-1 border-round-xl ">
            <div className="card-header">
              <div className="mt-2 border-round-lg h-10rem  relative card-bg ">
                <div className="flex justify-content-start">
                  <div className="flex col-3">
                    <img
                      src={employee?.profilePicture || USER}
                      className="w-4rem h-4rem cursor-pointer border-circle"
                      alt="Profile"
                      onClick={() =>
                        showDialog(employee.profilePicture || USER)
                      }
                    />
                  </div>
                  <div className="col-4">
                    <div className="flex py-2 w-4rem">
                      <span className="font-bold text-base ">
                        {employee?.firstName ?? previewData?.firstName ?? ''}{' '}
                      </span>
                      <span className="font-bold ml-2 text-base ">
                        {employee?.lastName ?? previewData?.lastName ?? ''}{' '}
                      </span>
                    </div>
                    <div className="w-16rem  mb-1 font-medium">
                      <i className="pi pi-briefcase  text-white text-xs"></i>
                      <span className="ml-2 text-xs text-white card-font">
                        {employee?.department?.name ??
                          selectedDepartment?.name ??
                          ''}{' '}
                      </span>
                    </div>
                    <div
                      className="flex  align-items-center font-medium mb-1 cursor-pointer"
                      onClick={handleCallClick}
                    >
                      <i className="pi   pi-phone text-white text-xs"></i>
                      <span className="ml-2  text-xs text-white card-font">
                        {employee?.mobileNumber ??
                          previewData?.mobileNumber ??
                          ''}{' '}
                      </span>
                    </div>
                    <div
                      className="flex  align-items-center w-16rem cursor-pointer"
                      onClick={handleMailClick}
                    >
                      <i className="pi pi-envelope  text-white text-xs"></i>
                      <span className="ml-2 text-xs text-white card-font">
                        {employee?.email ?? previewData?.email ?? ''}{' '}
                      </span>
                    </div>
                  </div>
                  <div className="col-5 text-right">
                    <Button
                      icon="pi pi-share-alt  text-sm"
                      aria-label="share"
                      onClick={handleShare}
                    />
                  </div>
                </div>
                <div className="flex p-2 w-full bg-white border-200 border-1  gap-2 absolute  border-round-bottom-xl mg-card-footer">
                  <Button
                    label={t('Download Contact')}
                    className="w-full  border-none"
                    onClick={handleDownloadVCard}
                  />
                  <Button
                    label={t('Exchange Info')}
                    className="w-full border-none"
                    onClick={openPopup}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              {employee?.tenant?.companyIntro && (
                <div className="text-center">
                  <h4>{t('About Us')}</h4>
                  <video controls className="w-full p-1" autoPlay loop muted>
                    <source
                      src={employee?.tenant?.companyIntro ?? ''}
                      type="video/mp4"
                    />
                    {t('Your browser does not support the video tag.')}
                  </video>
                </div>
              )}
            </div>
            {productsList && productsList.some((product) => product.active) && (
              <div className="mt-3 text-center">
                <h4>{t('Products')}</h4>
                <div className="grid ">
                  {productsList
                    .filter((product) => product.active)
                    .map((product, index) => (
                      <div
                        key={index}
                        className="col-6"
                        onClick={() => showProductsDialog(product)}
                      >
                        <div className="surface  h-auto p-1   w-full shadow-2 cursor-pointer border-round">
                          <div className="flex flex-column  align-items-center justify-content-between">
                            {product?.icon ? (
                              <img
                                src={product.icon}
                                alt={'Icon'}
                                className="h-6rem w-7rem border-round bg-cover mt-1 mb-2"
                              />
                            ) : (
                              <img
                                src={ICON_LOGO}
                                alt={'Icon'}
                                className="h-6rem w-7rem border-round bg-cover mt-1 mb-2"
                              />
                            )}

                            <div className="text-xs h-auto mb-2 whitespace-nowrap">
                              {product.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {aminitList && aminitList.some((service) => service.active) && (
              <div className="mt-3 text-center">
                <h4>{t('Services')}</h4>

                <div className="grid">
                  {aminitList
                    .filter((service) => service.active)
                    .map((service, index) => (
                      <div
                        key={index}
                        className="col-6"
                        onClick={() => showServiceDialog(service)}
                      >
                        <div className="surface  h-auto p-1  w-full shadow-2 cursor-pointer border-50 border-round ">
                          <div className="flex flex-column  align-items-center">
                            {service?.icon ? (
                              <img
                                src={service.icon}
                                alt={service.name}
                                className="h-6rem w-7rem bg-cover border-round bg-cover mt-1 mb-2"
                              />
                            ) : (
                              <img
                                src={ICON_LOGO}
                                alt={'Icon'}
                                className="h-6rem w-7rem border-round bg-cover mt-1 mb-2"
                              />
                            )}
                            <div className="text-xs h-auto mb-2 whitespace-nowrap">
                              {service.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          {array && array.some((items) => items.active) && (
            <div>
              <div className="mt-3 border-2 border-200   border-round-xl p-3">
                <Carousel
                  value={array.filter((items) => items.active)}
                  numVisible={1}
                  numScroll={1}
                  itemTemplate={itemTemplate}
                  showIndicators={false}
                />
              </div>
            </div>
          )}
          <Dialog
            header="Share Link"
            visible={share}
            style={{ maxWidth: '350px' }}
            onHide={() => setShare(false)}
          >
            <div className="flex justify-content-between px-2 mt-2 gap-2 w-full">
              <Button
                icon="pi pi-whatsapp text-800 text-2xl"
                rounded
                text
                aria-label="Whatsapp"
                onClick={() => handleShare('whatsapp')}
              />
              <Button
                icon="pi pi-facebook text-800 text-2xl"
                rounded
                text
                aria-label="Facebook"
                onClick={() => handleShare('facebook')}
              />
              <Button
                icon="pi pi-instagram text-800 text-2xl"
                rounded
                text
                aria-label="Instagram"
                onClick={() => handleShare('instagram')}
              />
              <Button
                icon="pi pi-twitter text-800 text-2xl"
                rounded
                text
                aria-label="Twitter"
                onClick={() => handleShare('twitter')}
              />
              <Button
                icon="pi pi-linkedin text-800 text-2xl"
                rounded
                text
                aria-label="Linkedin"
                onClick={() => handleShare('linkedin')}
              />
            </div>
            <div className="flex justify-content-center align-items-center gap-2 p-2 mt-2">
              <input
                className=" p-inputtext"
                value={selectService && selectService.id ? serviceUrl : userUrl}
                readOnly={true}
              />
              <Button
                icon="pi pi-copy text-800 "
                // rounded
                text
                aria-label="share"
                onClick={() => handleShare('copy')}
              />
            </div>
          </Dialog>
          <Dialog
            visible={visible}
            onHide={hideDialog}
            style={{ maxWidth: '300px' }}
          >
            <div className="text-center w-full">
              {isQRCode && selectedImage && (
                <QRCode value={selectedImage} size={200} />
              )}
              {!isQRCode && selectedImage && (
                <img
                  src={selectedImage}
                  alt="Selected"
                  className="w-full  cursor-pointer "
                />
              )}
            </div>
          </Dialog>

          <MzDialog
            visible={popupVisible}
            header={() => headerTemplate()}
            maximizable={false}
            onHide={closePopup}
          >
            <div className="flex  justify-content-center align-items-center w-full">
              <div style={{ maxWidth: '360px', minWidth: '350px' }}>
                <h4>{t('Exchange Information')}</h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid justify-content-center text-center border-round-sm">
                    <div className="col-12">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.NAME.name}
                        label={FORM_FIELDS_NAME.NAME.label}
                        placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                        type={FORM_FIELDS_NAME.NAME.type}
                        isError={errors[FORM_FIELDS_NAME.NAME.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.NAME.name
                        )}
                        rules={FORM_FIELDS_NAME.NAME.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.EMAIL.name}
                        label={FORM_FIELDS_NAME.EMAIL.label}
                        placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                        type={FORM_FIELDS_NAME.EMAIL.type}
                        isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.EMAIL.name
                        )}
                        rules={FORM_FIELDS_NAME.EMAIL.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12 relative">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.CONFIRM_EMAIL.name}
                        label={FORM_FIELDS_NAME.CONFIRM_EMAIL.label}
                        placeholder={FORM_FIELDS_NAME.CONFIRM_EMAIL.placeholder}
                        type={FORM_FIELDS_NAME.CONFIRM_EMAIL.type}
                        isError={!!errors[FORM_FIELDS_NAME.CONFIRM_EMAIL.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.CONFIRM_EMAIL.name
                        )}
                        rules={{
                          ...FORM_FIELDS_NAME.CONFIRM_EMAIL.rules,
                          validate: (value) =>
                            value === watch(FORM_FIELDS_NAME.EMAIL.name) ||
                            t('Email address do not match')
                        }}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12">
                      <MzPhoneInput
                        control={control}
                        name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                        label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                        placeholder={FORM_FIELDS_NAME.PHONE_NUMBER.placeholder}
                        rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                        isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.PHONE_NUMBER.name
                        )}
                        country="in"
                      />
                    </div>
                    <div className="col-12">
                      <MzTextarea
                        control={control}
                        name={FORM_FIELDS_NAME.ADDRESS.name}
                        label={FORM_FIELDS_NAME.ADDRESS.label}
                        placeholder={FORM_FIELDS_NAME.ADDRESS.placeholder}
                        value={watch(FORM_FIELDS_NAME.ADDRESS.name)}
                        isError={errors[FORM_FIELDS_NAME.ADDRESS.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.ADDRESS.name
                        )}
                        wrapperClass={'p-float-label'}
                        rows={3}
                      />
                    </div>
                    <div className="col-12">
                      <Button
                        type="submit"
                        label={t('Submit')}
                        className="w-full"
                        disabled={!isValid}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </MzDialog>

          <MzDialog
            header={() => headerTemplate()}
            visible={serviceVisible}
            onHide={hideServiceDialog}
            maximizable={false}
            height={'630px'}
            children={
              <div className="flex justify-content-center align-items-center w-full">
                <div style={{ maxWidth: '360px', minWidth: '350px' }}>
                  <div className="flex align-items-center border-circle w-full  text-xs">
                    {selectService?.icon ? (
                      <div className="flex justify-content-center col-2">
                        <img
                          src={selectService.icon}
                          className="w-2rem h-2rem border-circle"
                          alt="Logo"
                        />
                      </div>
                    ) : null}
                    <div className="font-bold text-sm col-10 overflow-hidden text-ellipsis whitespace-nowrap">
                      <span>{selectService?.name ?? ''}</span>
                    </div>
                  </div>
                  {selectService?.image && (
                    <img
                      src={selectService.image}
                      className=" h-13rem w-full mt-2  border-200   border-round"
                      alt=""
                    />
                  )}

                  <div className="text-center w-full">
                    {selectService?.video && (
                      <div className="mt-2">
                        <video controls className="w-full" autoPlay loop muted>
                          <source
                            src={selectService?.video}
                            className="w-30rem"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    )}
                    <div className="flex flex-wrap justify-content-center mt-2">
                      <Button
                        icon={isLiked ? 'pi pi-heart-fill' : 'pi pi-heart'}
                        label="Like"
                        text
                        outlined={false}
                        className="text-xs no-outline align-self-start border-0"
                        onClick={toggleLike}
                      />
                      <Button
                        icon="pi pi-share-alt"
                        label="Share"
                        text
                        outlined={false}
                        className="text-xs no-outline align-self-start border-0"
                        onClick={handleShare}
                      />
                    </div>
                    <div className="text-900 text-sm w-full mb-4 overflow-scroll">
                      <p className="whitespace-nowrap h-auto">
                        {selectService?.description}
                      </p>
                    </div>
                  </div>
                  <div className="flex p-2 bg-white w-full absolute bottom-0 left-0 gap-2 mg-card-footer">
                    <Button
                      label={t('Download brochure')}
                      className="text-xs w-full  border-none"
                      disabled={!selectService?.brochure}
                      onClick={handleDownloadBrochure}
                    />
                    <Button
                      label={t('Enquiry Now')}
                      className="text-xs w-full  border-none"
                      onClick={openEnquiryPopup}
                    />
                  </div>
                </div>
              </div>
            }
            footer={<div></div>}
          ></MzDialog>

          <MzDialog
            header={() => headerTemplate()}
            visible={productVisible}
            onHide={hideProductsDialog}
            maximizable={false}
            height={'630px'}
            children={
              <div className="flex justify-content-center align-items-center w-full">
                <div style={{ maxWidth: '360px', minWidth: '350px' }}>
                  <div className="flex align-items-center border-circle w-full text-xs">
                    {selectProduct?.icon ? (
                      <div className="flex justify-content-center col-2">
                        <img
                          src={selectProduct.icon}
                          className="h-2rem w-2rem border-circle"
                          alt="Logo"
                        />
                      </div>
                    ) : null}
                    <div className="font-bold text-sm col-10 overflow-hidden text-ellipsis whitespace-nowrap">
                      <span>{selectProduct?.name ?? ''}</span>
                    </div>
                  </div>
                  {selectProduct?.images && (
                    <Carousel
                      value={selectProduct?.images ?? []}
                      itemTemplate={(item) => (
                        <img
                          src={item.url}
                          alt={item}
                          style={{
                            width: '100%',
                            height: '150px',
                            objectFit: 'cover'
                          }}
                        />
                      )}
                      numVisible={1}
                      numScroll={1}
                      circular={true}
                      autoplayInterval={3000}
                      showIndicators={false}
                      showNavigators={false}
                    />
                  )}

                  <div className="text-center">
                    {selectProduct?.video && (
                      <div className="mt-2">
                        <video controls className="w-full" autoPlay loop muted>
                          <source
                            src={selectProduct?.video}
                            className="w-30rem"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    )}
                    <div className="flex w-full justify-content-center mt-2 ">
                      <Button
                        icon={
                          isLikedProduct ? 'pi pi-heart-fill' : 'pi pi-heart'
                        }
                        label="Like"
                        text
                        outlined={false}
                        className="text-xs no-outline align-self-start border-0"
                        onClick={toggleLikeProduct}
                      />
                      <Button
                        icon="pi pi-share-alt"
                        label="Share"
                        text
                        outlined={false}
                        className="text-xs no-outline align-self-start border-0"
                        onClick={handleShare}
                      />
                    </div>
                    <div className="text-900 text-sm w-full mb-4 overflow-scroll">
                      <p className="whitespace-nowrap h-auto ">
                        {selectProduct?.description}
                      </p>
                    </div>
                  </div>
                  <div className="flex p-2 bg-white w-full absolute bottom-0 left-0 gap-2 mg-card-footer">
                    <Button
                      label={t('Download brochure')}
                      className="text-xs w-full  border-none"
                      disabled={!selectProduct?.brochure}
                      onClick={handleDownloadBrochure}
                    />
                    <Button
                      label={t('Enquiry Now')}
                      className="text-xs w-full  border-none"
                      onClick={openEnquiryPopup}
                    />
                  </div>
                </div>
              </div>
            }
            footer={<div></div>}
          ></MzDialog>

          <MzDialog
            visible={enquiryVisible}
            header={() => headerTemplate()}
            maximizable={false}
            onHide={closeEnquiryPopup}
          >
            <div className="flex  justify-content-center align-items-center w-full">
              <div style={{ maxWidth: '360px', minWidth: '350px' }}>
                <h4>{t('Enquiry Now')}</h4>
                <form
                  onSubmit={
                    selectService
                      ? handleSubmit(onEnquirySubmit)
                      : handleSubmit(onProductEnquirySubmit)
                  }
                >
                  <div className="grid justify-content-center text-center  border-round-sm ">
                    <div className="col-12">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.NAME.name}
                        label={FORM_FIELDS_NAME.NAME.label}
                        placeholder={FORM_FIELDS_NAME.NAME.placeholder}
                        type={FORM_FIELDS_NAME.NAME.type}
                        isError={errors[FORM_FIELDS_NAME.NAME.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.NAME.name
                        )}
                        rules={FORM_FIELDS_NAME.NAME.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.EMAIL.name}
                        label={FORM_FIELDS_NAME.EMAIL.label}
                        placeholder={FORM_FIELDS_NAME.EMAIL.placeholder}
                        type={FORM_FIELDS_NAME.EMAIL.type}
                        isError={errors[FORM_FIELDS_NAME.EMAIL.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.EMAIL.name
                        )}
                        rules={FORM_FIELDS_NAME.EMAIL.rules}
                        wrapperClass={'p-float-label'}
                      />
                    </div>

                    <div className="col-12 relative">
                      <MzInput
                        control={control}
                        name={FORM_FIELDS_NAME.CONFIRM_EMAIL.name}
                        label={FORM_FIELDS_NAME.CONFIRM_EMAIL.label}
                        placeholder={FORM_FIELDS_NAME.CONFIRM_EMAIL.placeholder}
                        type={FORM_FIELDS_NAME.CONFIRM_EMAIL.type}
                        isError={errors[FORM_FIELDS_NAME.CONFIRM_EMAIL.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.CONFIRM_EMAIL.name
                        )}
                        rules={{
                          ...FORM_FIELDS_NAME.CONFIRM_EMAIL.rules,
                          validate: (value) =>
                            value === watch(FORM_FIELDS_NAME.EMAIL.name) ||
                            t('Email address do not match')
                        }}
                        wrapperClass={'p-float-label'}
                      />
                    </div>
                    <div className="col-12">
                      <MzPhoneInput
                        control={control}
                        name={FORM_FIELDS_NAME.PHONE_NUMBER.name}
                        label={FORM_FIELDS_NAME.PHONE_NUMBER.label}
                        placeholder={FORM_FIELDS_NAME.PHONE_NUMBER.placeholder}
                        rules={FORM_FIELDS_NAME.PHONE_NUMBER.rules}
                        isError={errors[FORM_FIELDS_NAME.PHONE_NUMBER.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.PHONE_NUMBER.name
                        )}
                        country="in"
                      />
                    </div>
                    <div className="col-12 ">
                      <MzTextarea
                        control={control}
                        name={FORM_FIELDS_NAME.ADDRESS.name}
                        label={FORM_FIELDS_NAME.ADDRESS.label}
                        placeholder={FORM_FIELDS_NAME.ADDRESS.placeholder}
                        value={watch(FORM_FIELDS_NAME.ADDRESS.name)}
                        isError={errors[FORM_FIELDS_NAME.ADDRESS.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.ADDRESS.name
                        )}
                        wrapperClass={'p-float-label'}
                        rows={2}
                      />
                    </div>
                    <div className="col-12 ">
                      <MzTextarea
                        control={control}
                        name={FORM_FIELDS_NAME.ADDITIONALINFO.name}
                        label={FORM_FIELDS_NAME.ADDITIONALINFO.label}
                        placeholder={
                          FORM_FIELDS_NAME.ADDITIONALINFO.placeholder
                        }
                        value={watch(FORM_FIELDS_NAME.ADDITIONALINFO.name)}
                        isError={errors[FORM_FIELDS_NAME.ADDITIONALINFO.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.ADDITIONALINFO.name
                        )}
                        wrapperClass={'p-float-label'}
                        rows={2}
                      />
                    </div>
                    <div className="col-12 ">
                      <MzTextarea
                        control={control}
                        name={FORM_FIELDS_NAME.NOTES.name}
                        label={FORM_FIELDS_NAME.NOTES.label}
                        placeholder={FORM_FIELDS_NAME.NOTES.placeholder}
                        value={watch(FORM_FIELDS_NAME.NOTES.name)}
                        isError={errors[FORM_FIELDS_NAME.NOTES.name]}
                        errorMsg={getFormErrorMessage(
                          FORM_FIELDS_NAME.NOTES.name
                        )}
                        wrapperClass={'p-float-label'}
                        rows={2}
                      />
                    </div>
                    {selectProduct && (
                      <div className="col-12">
                        <MzInput
                          control={control}
                          name={FORM_FIELDS_NAME.QUANTITY.name}
                          label={FORM_FIELDS_NAME.QUANTITY.label}
                          placeholder={FORM_FIELDS_NAME.QUANTITY.placeholder}
                          type={FORM_FIELDS_NAME.QUANTITY.type}
                          isError={errors[FORM_FIELDS_NAME.QUANTITY.name]}
                          errorMsg={getFormErrorMessage(
                            FORM_FIELDS_NAME.QUANTITY.name
                          )}
                          rules={FORM_FIELDS_NAME.QUANTITY.rules}
                          wrapperClass={'p-float-label'}
                        />
                      </div>
                    )}
                    <div className="col-12">
                      <Button
                        type="submit"
                        label={t('Submit')}
                        className="w-full"
                        disabled={!isValid}
                        //   disabled={isLoading || isPageLevelError}
                      ></Button>
                    </div>
                  </div>

                  <div></div>
                </form>
              </div>
            </div>
          </MzDialog>

          <MzDialog
            visible={myEnquiryVisible}
            header={headerTemplate}
            maximizable={false}
            onHide={closeMyServicesPopup}
          >
            <div className="flex justify-content-center align-items-center w-full">
              <div
                className=""
                style={{ maxWidth: '360px', minWidth: '350px' }}
              >
                <div className="mt-3 text-center">
                  <h4>My Enquiry</h4>
                  {enquiries.map((enquiry, index) => (
                    <Card
                      key={index}
                      title={enquiry.name}
                      style={{ width: '300px', marginBottom: '2em' }}
                      onClick={() => showDetails(enquiry)}
                      className="cursor-pointer"
                    >
                      <p>Date: {enquiry.date}</p>
                      <p>Description: {enquiry.category}</p>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </MzDialog>

          <MzDialog
            visible={otpPop}
            header={() => headerTemplate()}
            maximizable={false}
            onHide={closeOtpPop}
          >
            <div className="flex justify-content-center align-items-center h-full">
              <div className="col-12 flex flex-column align-items-center">
                <p>{t('Enter the OTP received on your email')}</p>
                <InputOtp
                  value={otp}
                  onChange={(e) => setOtp(e.value)}
                  length={6}
                  // integerOnly
                  className="otp-input"
                />
                <div className="flex gap-3 justify-content-center">
                  <Button
                    label={t('Verify')}
                    type="button"
                    className="mt-2 text-xs no-outline border-0"
                    onClick={onOtpSubmit}
                  />
                  <Button
                    label={t('Resend OTP')}
                    type="button"
                    className="mt-2 text-xs no-outline border-0"
                    text
                    outlined={false}
                    onClick={onOtpResend}
                  />
                </div>
                <div className="py-5">
                  {otpError && <small className="p-error">{otpError}</small>}
                  {otpSuccess && (
                    <small className="text-green-500">{otpSuccess}</small>
                  )}
                </div>
              </div>
            </div>
          </MzDialog>

          {selectedEnquiry && (
            <MzDialog
              visible={myEnquiryTrackVisible}
              header={headerTemplate}
              maximizable={false}
              onHide={closeDialog}
            >
              <div className="flex justify-content-center align-items-center w-full">
                <div style={{ maxWidth: '360px', minWidth: '350px' }}>
                  <div className="mt-3 text-center">
                    <Timeline
                      value={createTimelineEvents(selectedEnquiry.stages)}
                      align="alternate"
                      className="customized-timeline"
                      marker={(item) => (
                        <span
                          className="p-shadow-2"
                          style={{
                            backgroundColor: '#2196F3',
                            color: '#fff',
                            padding: '5px 10px',
                            borderRadius: '50%'
                          }}
                        >
                          {item.status.charAt(0)}
                        </span>
                      )}
                      content={(item) => (
                        <small className="p-text-secondary">
                          {item.date}
                          <p> {item.status}</p>
                        </small>
                      )}
                    />
                  </div>
                </div>
              </div>
            </MzDialog>
          )}
        </div>
      </div>
    </>
  )
}

export default CardComponent
